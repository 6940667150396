export var billingFormDetailsToPaymentSource = function (details) {
    if (details.type === "new-card") {
        return { type: "new-card", token: details.token };
    }
    // This is a bit hacky, need to define types better
    if (details.type === "we-chat-pay") {
        return { type: "we-chat-pay", token: "" };
    }
    return { type: details.type };
};
