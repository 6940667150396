var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import AsyncStatus from "../../utils/async";
import { mapState } from "vuex";
import { createStripe, isErrorResponse, } from "../../utils/stripe";
import { isWeChatSource } from "../../utils/payment";
import qrcode from "qrcode";
export default Vue.extend({
    props: {
        source: {
            type: String,
            required: true,
        },
        codeUrl: {
            type: String,
            required: true,
        },
        clientSecret: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            imageSource: undefined,
            checkStatus: new AsyncStatus(),
        };
    },
    computed: __assign({ stripe: function () {
            return createStripe(this.stripePublishableKey);
        } }, mapState(["stripePublishableKey"])),
    watch: {
        codeUrl: function (newValue) {
            this.renderQrCode(newValue);
        },
    },
    mounted: function () {
        this.renderQrCode(this.codeUrl);
        this.schedulePoll();
    },
    destroyed: function () {
        if (this.pollId) {
            clearTimeout(this.pollId);
        }
    },
    methods: {
        renderQrCode: function (newValue) {
            var _this = this;
            this.imageSource = undefined;
            qrcode.toDataURL(newValue, { width: 300 }).then(function (imageSource) {
                _this.imageSource = imageSource;
            });
        },
        checkSourceChargeable: function () {
            return this.stripe.retrieveSource({
                client_secret: this.clientSecret,
                id: this.source,
            });
        },
        schedulePoll: function () {
            this.pollId = window.setTimeout(this.pollSource.bind(this), 2000);
        },
        pollSource: function () {
            var _this = this;
            this.checkSourceChargeable().then(function (response) {
                _this.pollId = undefined;
                if (_this.checkStatus.isWorking) {
                    return;
                }
                if (isErrorResponse(response)) {
                    _this.schedulePoll();
                    return;
                }
                if (response.source.status === "pending") {
                    _this.schedulePoll();
                    return;
                }
                if (response.source.status === "chargeable") {
                    _this.$emit("complete");
                    return;
                }
                _this.$emit("failed");
            });
        },
        onSubmit: function () {
            var _this = this;
            this.checkStatus
                .run(this.checkSourceChargeable().then(function (response) {
                if (isErrorResponse(response)) {
                    throw new Error(response.error.message);
                }
                if (!isWeChatSource(response.source)) {
                    throw new Error("Invalid response, was expecting WeChat source");
                }
                if (response.source.status !== "chargeable") {
                    throw new Error(_this.$t("We couldn't perform the purchase, please try scanning the code again").toString());
                }
            }))
                .then(function () { return _this.$emit("complete"); })
                .catch(function (e) { return console.log(e); });
        },
    },
});
