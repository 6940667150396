var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import { addMoney, subtractMoney } from "../../../data";
import AsyncStatus from "../../../utils/async";
import BillingDetailsForm from "./BillingDetailsForm.vue";
export default Vue.extend({
    components: {
        BillingDetailsForm: BillingDetailsForm,
    },
    props: {
        useBankTransfer: {
            type: Boolean,
            required: true,
        },
        subTotal: {
            type: Object,
            required: true,
        },
        billingName: {
            type: String,
            required: true,
        },
        shippingPrice: {
            type: Object,
            default: undefined,
        },
        discount: {
            type: Object,
            default: undefined,
        },
        shippingAddress: {
            type: Object,
            default: undefined,
        },
        process: {
            type: Function,
            required: true,
        },
        viewerStudent: {
            type: Object,
            default: null,
        },
    },
    fragments: {
        viewerStudent: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment PaymentForm_viewerStudent on Student {\n        id\n        ...BillingDetailsForm_viewerStudent\n      }\n      ", "\n    "], ["\n      fragment PaymentForm_viewerStudent on Student {\n        id\n        ...BillingDetailsForm_viewerStudent\n      }\n      ", "\n    "])), BillingDetailsForm.options.fragments.viewerStudent),
    },
    data: function () {
        return {
            status: new AsyncStatus(),
        };
    },
    computed: {
        total: function () {
            var total = this.subTotal;
            if (this.shippingPrice) {
                total = addMoney(total, this.shippingPrice);
            }
            if (this.discount) {
                total = subtractMoney(total, this.discount);
            }
            return total;
        },
    },
    methods: {
        resetPaymentProcess: function () {
            this.status = new AsyncStatus();
        },
    },
});
var templateObject_1;
