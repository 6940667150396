var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { isErrorResponse, } from "./stripe";
import { moneyToZeroDecimalAmount } from "./money";
export function isWeChatSource(source) {
    return source.type === "wechat";
}
export function isAliPaySource(source) {
    return source.type === "alipay";
}
export function isBankTransferSource(source) {
    return source.type === "bank-transfer";
}
var PaymentPipeline = /** @class */ (function () {
    function PaymentPipeline(_a) {
        var $apollo = _a.$apollo, $router = _a.$router, $t = _a.$t, $store = _a.$store, stripe = _a.stripe;
        this.$apollo = $apollo;
        this.$t = $t;
        this.$router = $router;
        this.$store = $store;
        this.stripe = stripe;
    }
    PaymentPipeline.prototype.createAliPay = function (input) {
        var _this = this;
        return this.stripe
            .createSource(__assign({ type: "alipay", redirect: {
                // eslint-disable-next-line
                return_url: input.returnUrl,
            }, owner: this.startInputToSourceOwner(input) }, this.createNonIdrCnySourceAttrs(input)))
            .then(function (response) {
            if (isErrorResponse(response)) {
                throw new Error(response.error.message);
            }
            var source = response.source;
            if (!isAliPaySource(source)) {
                throw new Error("There was a problem making your payment");
            }
            return _this.createPendingPurchase(input, "stripe", source).then(function () { return source; });
        });
    };
    PaymentPipeline.prototype.createNonIdrCnySourceAttrs = function (input) {
        if (["IDR", "CNY"].includes(input.amount.currency)) {
            return {
                amount: moneyToZeroDecimalAmount({
                    currency: "AUD",
                    amount: input.amountAud,
                }),
                currency: "aud",
            };
        }
        return {
            amount: moneyToZeroDecimalAmount(input.amount),
            currency: input.amount.currency.toLowerCase(),
        };
    };
    PaymentPipeline.prototype.startInputToSourceOwner = function (_a) {
        var _b = _a.billingAddress, name = _b.name, address = _b.address;
        return {
            name: name,
            address: {
                line1: address.streetAddress1,
                line2: address.streetAddress2,
                city: address.city,
                postal_code: address.postcode,
                state: address.state,
                country: address.country,
            },
        };
    };
    PaymentPipeline.prototype.createWeChat = function (input) {
        var _this = this;
        return this.stripe
            .createSource(__assign({ type: "wechat", owner: this.startInputToSourceOwner(input) }, this.createNonIdrCnySourceAttrs(input)))
            .then(function (response) {
            if (isErrorResponse(response)) {
                throw new Error(response.error.message);
            }
            var source = response.source;
            if (!isWeChatSource(source)) {
                throw new Error(_this.$t("There was a problem making your payment").toString());
            }
            return _this.createPendingPurchase(input, "stripe", source).then(function () { return source; });
        });
    };
    PaymentPipeline.prototype.createBankTransfer = function (input) {
        return __awaiter(this, void 0, Promise, function () {
            var pendingPurchaseId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createPendingPurchase(input, "bank-transfer")];
                    case 1:
                        pendingPurchaseId = _a.sent();
                        return [2 /*return*/, { type: "bank-transfer", pendingPurchaseId: pendingPurchaseId }];
                }
            });
        });
    };
    PaymentPipeline.prototype.startInputToMutationInput = function (input) {
        return input;
    };
    PaymentPipeline.prototype.start = function (input) {
        if (input.paymentSource.type === "ali-pay") {
            return this.createAliPay(input);
        }
        if (input.paymentSource.type === "we-chat-pay") {
            return this.createWeChat(input);
        }
        if (input.paymentSource.type === "bank-transfer") {
            return this.createBankTransfer(input);
        }
        return this.performMutation(this.startInputToMutationInput(input));
    };
    return PaymentPipeline;
}());
export { PaymentPipeline };
